import { Directive, HostBinding, Input } from '@angular/core';
import { AbsenceStatus, EnhancedAbsenceModel } from '@app/reducers/orm/absence/absence.model';
import { AbsenteeOptionModel } from '@reducers/orm/absentee-option/absentee-option.model';
import { BadgeIconComponent } from '@sb/ui';
import { clsx } from 'clsx';

@Directive({
  selector: 'sb-badge-icon[absenceBadgeIcon]',
  standalone: true,
})
export class AbsenceBadgeIconDirective {
  @HostBinding('class')
  public classes = '';

  @HostBinding('style')
  public styles = {};

  @Input({ alias: 'absenceBadgeIcon' })
  public set absence(absence: EnhancedAbsenceModel) {
    this.absenceType = absence.absenteeOption;
    this.component.name = this.absenceType.icon;
    this.isPending = absence.status === AbsenceStatus.PENDING;
    this.setClassesAndStyles();
  }

  @Input()
  public set type(absenceType: AbsenteeOptionModel) {
    this.absenceType = absenceType;
    this.component.name = absenceType.icon;
    this.setClassesAndStyles();
  }

  private absenceType: AbsenteeOptionModel;
  private isPending = false;

  public constructor(private component: BadgeIconComponent) {}

  private setClassesAndStyles(): void {
    this.classes = clsx({
      /* eslint-disable @typescript-eslint/naming-convention */
      'text-white': !this.isPending && this.absenceType.color_is_dark,
      'text-word-mark-800': this.isPending || !this.absenceType.color_is_dark,
      'border-2 border-solid': true,
      /* eslint-enable @typescript-eslint/naming-convention */
    });

    this.styles = {
      backgroundColor: this.isPending ? 'white' : this.absenceType.color,
      borderColor: this.absenceType.color,
    };
  }
}
